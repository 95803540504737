/* Authentication related */

export const AUTHENTICATION_FAILURE = "AUTHENTICATION_FAILURE";
export const AUTH_TOKEN_TIMEOUT = "AUTH_TOKEN_TIMEOUT";
export const LOG_OUT = "LOG_OUT";

export const authenticationFailure = (errorMessage) => ({
    type: AUTHENTICATION_FAILURE,
    errorMessage: errorMessage
});

export const authTokenTimeout = (errorMessage) => ({
    type: AUTH_TOKEN_TIMEOUT,
    errorMessage: errorMessage
});

export const logOut = () => ({
    type: LOG_OUT
});

/* Fetch the list of accessible course year groups */

export const FETCH_COURSEYEARGROUPS_REQUEST = "FETCH_COURSEYEARGROUPS_REQUEST";
export const FETCH_COURSEYEARGROUPS_SUCCESS = "FETCH_COURSEYEARGROUPS_SUCCESS";
export const FETCH_COURSEYEARGROUPS_FAILURE = "FETCH_COURSEYEARGROUPS_FAILURE";

export const fetchCourseYearGroupsRequest = () => ({
    type: FETCH_COURSEYEARGROUPS_REQUEST
});

export const fetchCourseYearGroupsSuccess = (courseYearGroups) => ({
    type: FETCH_COURSEYEARGROUPS_SUCCESS,
    courseYearGroups: courseYearGroups
});

export const fetchCourseYearGroupsFailure = (errorMessage) => ({
    type: FETCH_COURSEYEARGROUPS_FAILURE,
    errorMessage: errorMessage
});

/* Fetch the list of students for a course year groups */

export const FETCH_STUDENTS_REQUEST = "FETCH_STUDENTS_REQUEST";
export const FETCH_STUDENTS_SUCCESS = "FETCH_STUDENTS_SUCCESS";
export const FETCH_STUDENTS_FAILURE = "FETCH_STUDENTS_FAILURE";

export const fetchStudentsRequest = () => ({
    type: FETCH_STUDENTS_REQUEST
});

export const fetchStudentsSuccess = (students) => ({
    type: FETCH_STUDENTS_SUCCESS,
    students: students
});

export const fetchStudentsFailure = (errorMessage) => ({
    type: FETCH_STUDENTS_FAILURE,
    errorMessage: errorMessage
});

/* Store selected course year group */

export const SAVE_SELECTED_COURSE_YEAR_GROUP = "SAVE_SELECTED_COURSE_YEAR_GROUP";

export const saveSelectedCourseYearGroup = (selectedCourseYearGroup) => ({
    type: SAVE_SELECTED_COURSE_YEAR_GROUP,
    selectedCourseYearGroup: selectedCourseYearGroup
});
