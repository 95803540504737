import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';

/* Props :
	- message => string.
	- autoHideDuration => integer (ms).
	- severity => string (error, warning, info, success).
*/
export function SnackBarMessage(props) {
	const [open, setOpen] = React.useState(true);

	const vertical = 'top';
	const horizontal = 'right';

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
		if (props.onClose) {
			props.onClose();
		}
	};

	return (
		<Snackbar
			open={open}
			autoHideDuration={props.autoHideDuration}
			onClose={handleClose}
			anchorOrigin={{ vertical, horizontal }}
			key={ vertical + horizontal }
		>
			<Alert
				onClose={handleClose}
				severity= {props.severity}
			>
				{props.message}
			</Alert>
		</Snackbar>
	);
}

SnackBarMessage.defaultProps = {
	autoHideDuration: 6000,
	severity: "info"
};


const Alert = React.forwardRef(function Alert(props, ref) {
	return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});