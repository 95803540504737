import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import DashboardIcon from '@material-ui/icons/Dashboard';
import DateRangeIcon from '@material-ui/icons/DateRange';
import RestoreIcon from '@material-ui/icons/Restore';
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle';
import SendIcon from '@material-ui/icons/Send';
import RouterIcon from '@material-ui/icons/Router';
import WorkIcon from '@material-ui/icons/Work';
import SettingsIcon from '@material-ui/icons/Settings';
import ScheduleIcon from '@material-ui/icons/Schedule';
import SchoolIcon from '@material-ui/icons/School';

import { AVAILABLE_FEATURES } from "../../config/index.js";

const drawerWidth = 220;
const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
    },
    drawer: {
        width: drawerWidth,
        flexShrink: 0,
    },
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    toolbar: theme.mixins.toolbar
}));

const featuresIcons = {
    "DashboardIcon"             : <DashboardIcon />,
    "DateRangeIcon"             : <DateRangeIcon />,
    "RestoreIcon"               : <RestoreIcon />,
    "SupervisedUserCircleIcon"  : <SupervisedUserCircleIcon />,
    "SendIcon"                  : <SendIcon />,
    "RouterIcon"                : <RouterIcon />,
    "WorkIcon"                  : <WorkIcon />,
    "SettingsIcon"              : <SettingsIcon />,
    "ScheduleIcon"              : <ScheduleIcon />,
    "SchoolIcon"                : <SchoolIcon />
}

export default function MainDrawer(props) {
    const classes = useStyles();
    const authorizedFeaturesForUser = (localStorage.features === undefined ? "" : localStorage.features);
    return (
            <Drawer
                className={classes.drawer}
                variant="permanent"
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <div className={classes.toolbar} />
                <List>
                    {Object.entries(AVAILABLE_FEATURES).map(([key, feature]) => {
                        if (!feature.requireAdmin && feature.active && authorizedFeaturesForUser.includes(key)) {
                            return (
                                <ListItem button component={Link} to={'/' + feature.url} key={key}>
                                    <ListItemIcon>{featuresIcons[feature.icon]}</ListItemIcon>
                                    <ListItemText primary={feature.text} />
                                </ListItem>
                            );
                        } else {
                            return null;
                        }
                    })}
                </List>
                <React.Fragment>
                    <Divider />
                    <List>
                        {Object.entries(AVAILABLE_FEATURES).map(([key, feature]) => {
                            if (feature.requireAdmin && feature.active && authorizedFeaturesForUser.includes(key)) {
                                return (
                                    <ListItem button component={Link} to={'/' + feature.url} key={key}>
                                        <ListItemIcon>{featuresIcons[feature.icon]}</ListItemIcon>
                                        <ListItemText primary={feature.text} />
                                    </ListItem>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </List>
                </React.Fragment>
        </Drawer>
    );
}
