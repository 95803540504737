import * as React from 'react';

import { makeStyles } from '@mui/styles';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import ListSubheader from '@mui/material/ListSubheader';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';

const useStyles = makeStyles({
    root: {
      backgroundColor: 'transparent !important'
    }
});

/* Props: 
        - handleCourseYearGroupSelection    => function(string).
        - courseYearGroups                  => array: [{schoolName: string, name: string}]).
*/
export function CourseYearGroupPicker(props) {
    const [courseYearGroup, setCourseYearGroup] = React.useState(props.selectedCourseYearGroup || '');
    const classes = useStyles(props);
    
    const handleChange = (e) => {
        if (e.target.value) {
            setCourseYearGroup(e.target.value);
            props.handleCourseYearGroupSelection(e.target.value);
        }
    }

    return (
        <FormControl 
            variant="filled"
            sx={{ m: 1, minWidth: 240 }}
            disabled={props.disabled}
        >
            <InputLabel htmlFor="course-year-group">Sélectionner une promotion</InputLabel>
            <Select
                id="course-year-group"
                onChange={handleChange}
                value={courseYearGroup}
                className={classes.root}
            >
                { props.courseYearGroups ? toComponents(toMap(props.courseYearGroups)) : null }
            </Select>
        </FormControl>
    );
}

const toMap = (courseYearGroups) =>  {
    const schoolToCourseYearGroupsMap = new Map();
    courseYearGroups.sort((cyg1, cyg2) => cyg1.schoolName.localeCompare(cyg2.schoolName)).forEach(courseYearGroup => {
        let school = schoolToCourseYearGroupsMap.get(courseYearGroup.schoolName);
        if (school) {
            school.push(courseYearGroup.name);
        } else {
            school = [courseYearGroup.name];
            schoolToCourseYearGroupsMap.set(courseYearGroup.schoolName, school);
        }
    });

    return schoolToCourseYearGroupsMap;
}

const toComponents = (schoolToCourseYearGroupsMap) => {
    const components = [];
    schoolToCourseYearGroupsMap.forEach((courseYearGroups, schoolName) => {
        components.push(<ListSubheader key={schoolName}>{ schoolName }</ListSubheader>);
        courseYearGroups.sort().forEach((cygName) => components.push(<MenuItem value={cygName} key={cygName}>{cygName}</MenuItem>));
    });

    return components;
}