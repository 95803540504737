import DashboardContainer from "../../pages/business-pages/dashboard-page/DashboardContainer";
import RealTimeAttendancyViewContainer from "../../pages/business-pages/real-time-attendancy-page/RealTimeAttendancyViewContainer";
import CourseSessionValidationViewContainer from "../../pages/business-pages/course-sessions-declaration-page/CourseSessionValidationViewContainer";
import AbsencesHandlingViewContainer from "../../pages/business-pages/absence-handling-page/AbsencesHandlingViewContainer";
import StudentsAttendanceMonitoringViewContainer from "../../pages/business-pages/students-attendance-monitoring-page/StudentsAttendanceMonitoringViewContainer";
import AttendanceCertificatesContainer from "../../pages/unused-pages/attendance-certificates-management-page/AttendanceCertificatesContainer";
import CardReaderTableContainer from "../../pages/admin-pages/card-readers-monitoring-page/CardReaderTableContainer";
import DataManagementContainer from "../../pages/admin-pages/data-management-page/DataManagementContainer";
import ServerManagementViewContainer from "../../pages/admin-pages/server-management-page/ServerManagementViewContainer";
import StudentAccessPageViewContainer from "../../pages/business-pages/student-access-page/StudentAccessPageViewContainer";
import CardManagementPageViewContainer from "../../pages/admin-pages/card-management-page/CardManagementPageViewContainer";

//export const MAIN_SERVER_URL = "http://localhost:5000/";
export const MAIN_SERVER_URL = "https://api.alumneo.fr/";
export const TENANTS_LIST = ["test", "itii-pdl"]; /* Also used for subdomains rooting, always use lowercase, no underscore, etc */
export const LOGIN_ROUTE = "login";
export const RESET_PASSWORD_ROUTE = "reset-password";

// For Absence view, order is important.
export const ABSENCE_VIEW_MODES = [
    {name:"Vue Compacte", tooltip:"Cette vue regroupe les absences continues sur une même ligne"},
    {name:"Vue Journée", tooltip:"Cette vue regroupe les absences par jour sur une même ligne"},
    {name:"Vue Détaillée", tooltip:"Cette vue affiche une absence à un cours par ligne"}
];
export const ABSENCE_STATUS_FILTERS = ["Non traitées", "Traitées", "Supprimées"];

/* This config is mostly used by AppRoutes (to create routes) and MainDrawer */
export const DEFAULT_HOME_PAGE = RealTimeAttendancyViewContainer;
export const DEFAULT_HOME_STUDENT_PAGE = StudentAccessPageViewContainer;

export const AVAILABLE_FEATURES = {
    "DASHBOARD": {
        icon        : "DashboardIcon",
        text        : "Dashboard",
        url         : "dashboard",
        container   : DashboardContainer,
        active      : false,
        requireAdmin: false
    },
    "REAL_TIME_ATTENDANCE": {
        icon        : "ScheduleIcon",
        text        : "Suivi temps réel",
        url         : "suivi-temps-reel",
        container   : RealTimeAttendancyViewContainer,
        active      : true,
        requireAdmin: false
    },
    "COURSE_SESSION_MANAGER": {
        icon        : "DateRangeIcon",
        text        : "Déclaration des cours",
        url         : "declaration-cours",
        container   : CourseSessionValidationViewContainer,
        active      : true,
        requireAdmin: false
    },
    "ABSENCE_MANAGER": {
        icon        : "RestoreIcon",
        text        : "Traitement des absences",
        url         : "traitement-absences",
        container   : AbsencesHandlingViewContainer,
        active      : true,
        requireAdmin: false
    },
    "STUDENT_MONITORING": {
        icon        : "SupervisedUserCircleIcon",
        text        : "Suivi des élèves",
        url         : "suivi-eleves",
        container   : StudentsAttendanceMonitoringViewContainer,
        active      : true,
        requireAdmin: false
    },
    "ATTENDANCE_RECORD_MANAGER": {
        icon        : "SendIcon",
        text        : "Attestations de présence",
        url         : "attestations-presence",
        container   : AttendanceCertificatesContainer,
        active      : false,
        requireAdmin: false
    },
    "CARD_READER_MONITORING": {
        icon        : "RouterIcon",
        text        : "Monitoring badgeuse",
        url         : "monitoring-badgeuses",
        container   : CardReaderTableContainer,
        active      : true,
        requireAdmin: true
    },
    "DATA_MANAGEMENT": {
        icon        : "WorkIcon",
        text        : "Données SI",
        url         : "donnees-si",
        container   : DataManagementContainer,
        active      : true,
        requireAdmin: true
    },
    "ADMINISTRATION": {
        icon        : "SettingsIcon",
        text        : "Administration",
        url         : "administration",
        container   : CardManagementPageViewContainer,
        active      : true,
        requireAdmin: true
    }
};

export const AVAILABLE_FEATURES_STUDENT = {
    "STUDENT_ACCESS": {
        icon        : "SchoolIcon",
        text        : "Accès étudiant",
        url         : "acces-etudiant",
        container   : StudentAccessPageViewContainer,
        active      : true,
        requireAdmin: false
    }
}