import { authenticationFailure } from "../../../../shared/redux/actions";
import { fetchWithTimeOut } from "../../../../shared/utils/networking";
import { MAIN_SERVER_URL } from "../../../../shared/config/index.js";

import { fetchActuatorsRequest, fetchActuatorsSuccess, fetchActuatorsFailure } from "./ServerManagementActions";

/** Send request to get stats about students absences  */
export function fetchActuators(endpoint) {
    return function (dispatch) {
        dispatch(fetchActuatorsRequest());

        const url = MAIN_SERVER_URL + endpoint;
        const token = localStorage.token;

        return fetchWithTimeOut(url, { method: "GET", headers: { "Authorization": token } })
            .then(response => {
                if (response.status === 200) {
                    return response.text();
                } else {
                    throw response;
                }
            })
            .then(result => {
                dispatch(fetchActuatorsSuccess(result));
            })
            .catch(error => {
                if (error.status === 403) {
                    localStorage.clear();
                    dispatch(authenticationFailure("Votre session a expiré, veuillez vous reconnecter."));
                } else {
                    dispatch(fetchActuatorsFailure("Une erreur est survenue. Veuillez contacter un administrateur."));
                }
            });
    }
}