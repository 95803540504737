import { connect } from "react-redux";

import Login from "./Login";
import { fetchAuthentication, sendPasswordResetRequest } from "../../../shared/redux/services";

const mapStateToProps = state => {
    return {
        isLoggedIn: state.LoginReducer.isLoggedIn,
        username: state.LoginReducer.username,
        errorMessage: state.LoginReducer.errorMessage,
        isLoading: state.LoginReducer.isLoading,
        passwordResetIsLoading: state.PasswordResetReducer.isFetching,
        passwordResetSuccessful: state.PasswordResetReducer.isSuccessful,
        passwordResetErrorMessage: state.PasswordResetReducer.errorMessage
    };
};

function mapDispatchToProps(dispatch) {
    return {
        sendAuthenticationRequest: (username, password) => {
            dispatch(fetchAuthentication(username, password));
        },
        sendPasswordResetRequest: (emailAddress) => {
            dispatch(sendPasswordResetRequest(emailAddress));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);