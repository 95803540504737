import * as actions from "../actions";
import * as endpoints from "../api";
import { simpleCallService, getDateString } from "../../utils/networking";

/** Send request to get students for a given course year group  */
export function fetchStudentsAttendanceStats(courseYearGroup, startTime, endTime) {
	return simpleCallService(
		endpoints.COURSE_YEAR_GROUP_STATS_ENDPOINT + "?course-year-group=" + courseYearGroup + "&startingDate=" + getDateString(startTime.toDate()) + "&endingDate=" + getDateString(endTime.toDate()),
		"GET",
		actions.fetchStudentsAttendanceStatsRequest,
		actions.fetchStudentsAttendanceStatsSuccess,
		actions.fetchStudentsAttendanceStatsFailure,
		null,
		true
	);
}

/*const DATA3 = [
    { 
        courseYearGroupName: 'ESAIP Test - SPR 2020',
        studentId: 5,
        studentFirstName: 'Bob',
        studentLastName: 'Ross',
        month: moment(new Date()).format('MMM YYYY'),
        numberOfMandatoryCourseSessions: 10,
        numberOfAbsences: 3,
        courseSessionAttendances: [
            {
                courseSessionId: 100,
                discipline: "Math",
                startTIme: "",
                endTime: "",
                absenceId: 110,
                processed: true,
                motiveType: "En retard",
                error: false,
                complementaryNote: "-",
                justified: false,
                absenceDurationInMinute: 30
            }
        ] }
];*/
