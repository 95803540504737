import React from "react";
import moment from 'moment';
import MaterialTable from '@material-table/core';

import { makeStyles } from '@mui/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';

import { SimpleDialog } from '../../../shared/components';

const useStyles = makeStyles({
    textBlock: {
        margin: '10px !important'
    }
});

export default function AttendancyEventModal(props) {
    const classes = useStyles();

    const getModalContentForEvent = (event) => {
        const pageSizeOptions = event.presentStudents.length > 30 ? 30 : event.presentStudents.length;
        const tableData = event.presentStudents.map(presentStudent => { return {
            student: presentStudent.lastName + " " + presentStudent.firstName,
            firstLogDuringEvent: moment(presentStudent.firstLogForPeriod).format("dddd DD MMM YYYY  -  HH:mm")
        }});
        const content = (
            <div>
                <Typography variant="h6" className={classes.textBlock}>Etudiants n'ayant pas {event.isOccurring? "encore" : null} badgés sur ce créneau horaire :</Typography>
                <Grid container spacing={3}>
                    {event.absentStudents.map((student, index) => (
                        <Grid item xs={3} key={"grid-" + index}>
                            <Typography key={index}>{student.lastName} {student.firstName}</Typography>
                        </Grid>
                    ))}
                </Grid>
                <Divider className={classes.textBlock} />
                <Typography variant="h6" className={classes.textBlock}>Etudiants ayant badgés sur ce créneau horaire :</Typography>
                <MaterialTable
                    columns={[
                        { title: 'Etudiant', field: 'student', defaultSort: "asc" },
                        { title: 'Premier badgeage sur ce créneau', field: 'firstLogDuringEvent', type: 'datetime' }
                    ]}
                    data={tableData}
                    options={{
                        toolbar: false,
                        selection: false,
                        filtering: false,
                        sorting: true,
                        pageSize: pageSizeOptions,
                        pageSizeOptions: [pageSizeOptions, 20, 50],
                        search: false
                    }}
                    localization={{
                        pagination: {
                            labelRowsSelect: "lignes"
                        },
                        body: {
                            emptyDataSourceMessage: 'Aucun étudiant présent.'
                        }
                    }}
                />
            </div>
        );
        return {
            title: "Détails des présences et absences",
            text: content
        }
    }

    return (
        <SimpleDialog 
            open={props.open}
            onClose={props.onClose}
            content={getModalContentForEvent(props.event)}
        />
    );
}