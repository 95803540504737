import { connect } from "react-redux";

import App from "./App";
import { authenticationSuccess } from "../shared/redux/actions";

function mapDispatchToProps(dispatch) {
    return {
        setLoggedIn: (username) => {
            dispatch(authenticationSuccess(username));
        }
    };
}

export default connect(undefined, mapDispatchToProps)(App);