import React, { useState, useEffect } from "react";

import LoggedInLayout from '../../../shared/layouts/logged-in/LoggedInLayout';
import AbsencesHandlingTopBar from './AbsencesHandlingTopBar';
import AbsencesHandlingTable from './AbsencesHandlingTable';
import { CancelMotiveModal, CreateAbsenceModal, CreateMotiveModal, DeleteAbsenceModal } from './workflows';
import { SnackBarErrorMessageGroup } from '../../../shared/components';
import { ABSENCE_VIEW_MODES, ABSENCE_STATUS_FILTERS } from '../../../shared/config';

export default function AbsencesHandlingView(props) {
    const [selectedCourseYearGroup, setSelectedCourseYearGroup] = useState('');
    const [currentView, setCurrentView] = useState({
        viewMode: ABSENCE_VIEW_MODES[0],
        statusFilter:ABSENCE_STATUS_FILTERS[0]});
    const [displayCreateAbsenceModal, setDisplayCreateAbsenceModal] = useState(false);
    const [displayDeleteAbsenceModal, setDisplayDeleteAbsenceModal] = useState(false);
    const [displayCreateMotiveModal, setDisplayCreateMotiveModal] = useState(false);
    const [displayCancelMotiveModal, setDisplayCancelMotiveModal] = useState(false);
    const [selectedData, setSelectedData] = useState(null);

    const { sendGetCourseYearGroupsRequest, sendGetAbsenceMotiveTypesRequest } = props;

    /* Fetching Available course year groups and motive types once */
    useEffect(() => {
        sendGetCourseYearGroupsRequest();
        sendGetAbsenceMotiveTypesRequest();
    }, [sendGetCourseYearGroupsRequest, sendGetAbsenceMotiveTypesRequest]);

    /* When new course year group is selected, fetch absences */
    const handleCourseYearGroupSelection = (newCourseYearGroup) => {
        if (newCourseYearGroup !== selectedCourseYearGroup) {
            setSelectedCourseYearGroup(newCourseYearGroup);
            fetchAbsences(newCourseYearGroup, currentView.statusFilter);
        }
    };

    /* When new option is selected, display new view */
    const displayNewView = (viewMode, statusFilter) => {
        if (statusFilter !== currentView.statusFilter && selectedCourseYearGroup) {
            fetchAbsences(selectedCourseYearGroup, statusFilter);
        }
        setCurrentView({ viewMode: viewMode, statusFilter: statusFilter });
    };

    const fetchAbsences = (courseYeargroup, statusFilter) => {
        props.sendGetAbsencesRequest(courseYeargroup, (statusFilter !== ABSENCE_STATUS_FILTERS[0]), (statusFilter === ABSENCE_STATUS_FILTERS[2]));
    }

    const handleCreateAbsence = () => {
        setDisplayCreateAbsenceModal(true);
    }

    const handleDeleteAbsences = (selectedData) => {
        setSelectedData(selectedData);
        setDisplayDeleteAbsenceModal(true);
    }

    const handleCreateMotive = (selectedData) => {
        setSelectedData(selectedData);
        setDisplayCreateMotiveModal(true);
    }

    const handleCancelMotive = (selectedData) => {
        setSelectedData(selectedData);
        setDisplayCancelMotiveModal(true);
    }

    const generateModalContent = (displayModal, setDisplayModal, sendRequest, otherPairs) => {
        const onCloseCallback = (hasSentRequest) => {
            setDisplayModal(false);
            if (hasSentRequest) {
                fetchAbsences(selectedCourseYearGroup, currentView.statusFilter);
            }
        }
        return {
            open: displayModal,
            onClose: onCloseCallback,
            data: selectedData,
            sendRequest: sendRequest,
            isLoading: props.isWorflowLoading,
            success: props.isWorkflowSuccess,
            failure: props.isWorkflowFailure,
            ...otherPairs
        }
    }

    return (
        <LoggedInLayout isLoggedIn={props.isLoggedIn} username={props.username}>
            <AbsencesHandlingTopBar
                absenceStatusFilters={ABSENCE_STATUS_FILTERS}
                absenceViewModes={ABSENCE_VIEW_MODES}
                displayNewView={displayNewView}
                courseYearGroups={props.courseYearGroups}
                handleCourseYearGroupSelection={handleCourseYearGroupSelection}
            />
            <AbsencesHandlingTable
                currentView={currentView}
                isLoading={props.isLoading}
                absences={props.absences}
                handleCreateAbsence={handleCreateAbsence}
                handleDeleteAbsences={handleDeleteAbsences}
                handleCreateMotive={handleCreateMotive}
                handleCancelMotive={handleCancelMotive}
            />
            <SnackBarErrorMessageGroup
                messages={[props.courseYearGroupsErrorMessage, props.absenceErrorMessage, props.studentsErrorMessage]}
            />
            { displayCreateAbsenceModal ?
                <CreateAbsenceModal
                    {...generateModalContent(displayCreateAbsenceModal, setDisplayCreateAbsenceModal,
                        props.sendCreateAbsenceRequest, { fetchStudentsRequest: () => props.fetchStudentsRequest(selectedCourseYearGroup), students: props.students })} />
                : null
            }
            { displayDeleteAbsenceModal ?
                <DeleteAbsenceModal
                    {...generateModalContent(displayDeleteAbsenceModal, setDisplayDeleteAbsenceModal,
                        props.sendDeleteAbsenceRequest, { motiveTypes: props.motiveTypes })} />
                : null
            }
            { displayCreateMotiveModal ?
                <CreateMotiveModal
                    {...generateModalContent(displayCreateMotiveModal, setDisplayCreateMotiveModal,
                        props.sendCreateMotiveRequest, { motiveTypes: props.motiveTypes })} />
                : null
            }
            { displayCancelMotiveModal ?
                <CancelMotiveModal
                    {...generateModalContent(displayCancelMotiveModal, setDisplayCancelMotiveModal,
                        props.sendCancelMotiveRequest)} />
                : null
            }
        </LoggedInLayout>
    );
}
