import React, { useState } from "react";

import { Calendar, Views, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/fr';

import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import Popper from '@mui/material/Popper';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Alert from '@mui/material/Alert';
import ClickAwayListener from '@mui/material/ClickAwayListener';

const messages = {
    allDay: 'Journée',
    previous: 'Précédent',
    next: 'Suivant',
    today: 'Aujourd\'hui',
    month: 'Mois',
    week: 'Semaine',
    work_week: 'Semaine de travail',
    day: 'Jour',
    agenda: 'Agenda',
    date: 'Date',
    time: 'Heure',
    event: 'Cours',
    showMore: total => `+ ${total} cours supplémentaire(s)`
}

export default function CourseSessionCalendar(props) {
    const [anchorEl, setAnchorEl] = useState(null);
    const [open, setOpen] = useState(false);
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [view, setView] = useState(props.defaultView);

    /* Greying the table when data is loading. */
    const calendarStyle = () => {
        if (props.isLoading) {
            return { height: "80vh", padding: "10px", opacity: 0.50, pointerEvents: "none" };
        } else {
            return { height: "80vh", padding: "10px" };
        }
    };

    /* When user changes date, either by moving the window or changing the view */
    const handleDateRangeChange = (currentDateRange, newView) => {
        const currentView = (newView ? newView : view);
        let startDate, endDate;

        if (currentView === Views.MONTH) {
            const firstDisplayedDay = currentDateRange.start;
            if (firstDisplayedDay.getDate() > 1) {
                startDate = new Date(currentDateRange.start.getFullYear(), currentDateRange.start.getMonth() + 1, 1);
                endDate = new Date(currentDateRange.start.getFullYear(), currentDateRange.start.getMonth() + 2, 0);
            } else {
                startDate = currentDateRange.start;
                endDate = new Date(currentDateRange.start.getFullYear(), currentDateRange.start.getMonth() + 1, 0);
            }
        } else {
            startDate = currentDateRange[0];
            endDate = new Date(currentDateRange[currentDateRange.length - 1]);
            endDate.setDate(endDate.getDate() + 1);
        }

       props.handleDateRangeChange(startDate, endDate);
       setView(currentView);
    };

    /* When the user click on an event, display the popper if the event is not already selected.
       If the element is already selected, deselect it and hide the popper. */
    const handleClickOnEvent = (event, calendarElement) => {
        if (event !== selectedEvent) {
            setOpen(true);
            setAnchorEl(calendarElement.target);
            setSelectedEvent(event);
        } else {
            setOpen(false);
            setSelectedEvent(null);
        }
    };

    /* When user clicks away of the popper, unselect the event (if selected). */
    const handleClickAway = () => {
        if (selectedEvent) {
            setOpen(false);
            setSelectedEvent(null);
        }
    }

    /* When a course session is marked as optional (or unmarked), update the event accordingly (will change the color). */
    const handleOptionalChange = (event) => {
        props.handleOptionalChange(selectedEvent.index, event.target.checked);
    }

    /* Based on the event parameters, change the color accordingly. */
    const getEventStyle = (event) => {
        const style = { backgroundColor: "#2471a3", color: "#FFFFFF", border: "0px" }; // blue
        if (event.confirmed) {
            style.backgroundColor = "#229954"; // green
        } else if (event.isOptional) {
            style.backgroundColor = "#999e9b"; // grey
        } else if (hasBlockingErrors(event)) {
            style.backgroundColor = "#a93226"; // red
        } else if (event.errors.length) {
            style.backgroundColor = "#d4ac0d"; // yellow
        }
        return { style: style };
    }

    /* Content of the popper displayed when user clicks on an event to indicate it is optional */
    const getPopperContent = (event) => {
        const elements = [];
        if (event.confirmed) {
            elements.push(<Alert severity="success">Ce cours a déjà été validé, veuillez contacter un administrateur si une correction est nécessaire.</Alert>);
        } else if (hasBlockingErrors(event)) {
            elements.push(<Alert severity="error">Ce cours ne peut être validé car il présente une erreur bloquante :
                <ul>
                    {event.errors.filter(error => error.blocking).map(error => {
                        return (<li>{error.description}</li>);
                    })}
                </ul>
            </Alert>);
        } else if (event.errors.length > 0) {
            elements.push(<Alert severity="warning">Ce cours peut être validé mais contient potentiellement une anomalie :
                <ul>
                    {event.errors.filter(error => !error.blocking).map(error => {
                        return (<li>{error.description}</li>);
                    })}
                </ul>
            </Alert>);
        }

        elements.push(<Typography><strong>Matière : </strong>{event.title}</Typography>);

        if (!event.confirmed && !hasBlockingErrors(event)) {
            elements.push(<FormControlLabel control={<Checkbox onChange={handleOptionalChange} checked={event.isOptional} />} label="Cochez cette case si la présence n'est pas relevée" />);
        }

        return elements;
    }

    /* Returns true if an event has a blocking error */
    const hasBlockingErrors = (event) => {
        return event.errors && event.errors.some(error => error.blocking);
    }

    return (
        <Paper>
            <div style={calendarStyle()}>
                <Calendar
                    events={props.events}
                    views={[Views.MONTH, Views.WEEK, Views.WORK_WEEK, Views.DAY]}
                    defaultView={props.defaultView}
                    defaultDate={props.defaultDate}
                    culture={"fr"}
                    localizer={momentLocalizer(moment)}
                    messages={messages}
                    min={moment('06:00am', 'h:mma').toDate()}
                    max={moment('10:00pm', 'h:mma').toDate()}
                    onRangeChange={(a, b) => handleDateRangeChange(a, b)}
                    eventPropGetter={getEventStyle}
                    onSelectEvent={handleClickOnEvent}
                />
            </div>
            <Popper open={open} anchorEl={anchorEl} placement={'bottom-start'}>
                <ClickAwayListener onClickAway={handleClickAway}>
                    <Paper elevation={8} sx={{ padding: '5px' }}>
                        {selectedEvent ? getPopperContent(selectedEvent) : null}
                    </Paper>
                </ClickAwayListener>
            </Popper>
            {props.isLoading ?
                <div style={{ position: "relative", top: "-60vh" }}> <CircularProgress /> </div>
                : null
            }
        </Paper>
    );
}
