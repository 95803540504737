import { LOGIN_ROUTE } from "../config";

/** 
 * Considering the TLD is not containing dot (so like .fr or .com, but not .co.uk),
 * This will return the first subdomain (so www.abc.def.alumneo.fr => returns def).
 * There is an exception for localhost (no TLD).
 * 
 * For now, we only have .fr, and only 1 subdomain level allowed anyway
*/
export function getSubdomain() {
    const split =  window.location.hostname.split(".");
    if (split.length > 2) {
        return split[split.length - 3];
    } else if (split.length === 2 && split[1] === "localhost") {
        return split[0];
    } else {
        return "";
    }
}

/** 
 *  Considering the TLD is not containing dot (so like .fr or .com, but not .co.uk),
 *  This will redirect to the provided subdomain.
*/
export function getCurrentURLWithSubdomain(subdomain) {
    const host = window.location.host;
    const protocol = window.location.protocol;

    const currentSubdomain = getSubdomain();
    if (currentSubdomain) {
        const split = host.split(currentSubdomain + "."); /* If subdomain is equal to the end of the apex domain (like eo.alumneo.fr), this would bug. */
        return protocol + "//" + subdomain + "." + split[split.length - 1];
    } else {
        return protocol + "//" + subdomain + "." + host;
    }
}

export function rerouteToTenantLogin(tenant) {
    window.location.replace(getCurrentURLWithSubdomain(tenant) + "/" + LOGIN_ROUTE);
}

export function redirectToLoginPage() {
    localStorage.clear();
    window.location.pathname = LOGIN_ROUTE;
}