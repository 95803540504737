import React from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { blue, indigo, grey } from '@mui/material/colors';

import AppRoutes from './AppRoutes';

import '../shared/styles/App.css';

/* Material-ui Theming explained here: https://material-ui.com/customization/theming/ */
const theme = createTheme({
    palette: {
        secondary: {
            main: blue[900]
        },
        primary: {
            main: indigo[700]
        },
        lightGrey: {
            main: grey[300]
        }
    }
});

export default function App(props) {
    if (localStorage.username !== undefined) {
        props.setLoggedIn(localStorage.username);
    }
    return (
        <React.Fragment>
            <CssBaseline />
            <div className="App">
                <ThemeProvider theme={theme}>
                    <AppRoutes />
                </ThemeProvider>
            </div>
        </React.Fragment>
    );
}