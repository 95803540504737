import * as types from "./CardReadersActions";

export const initialState = {
  cardReaders: [],
  dateRequest: undefined,
  errorMessage:undefined,
  isFetching:false
};

const CardReadersReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_CARDREADERS_REQUEST :
          return Object.assign({}, state, {
            cardReaders: [],
            dateRequest: undefined,
            errorMessage: undefined,
            isFetching: true
          });
        case types.FETCH_CARDREADERS_SUCCESS :
          return Object.assign({}, state, {
            cardReaders: action.cardReaders,
            dateRequest: action.dateRequest,
            errorMessage: undefined,
            isFetching: false
          });
        case types.FETCH_CARDREADERS_FAILURE :
          return Object.assign({}, state, {
            cardReaders: [],
            dateRequest: undefined,
            errorMessage: action.errorMessage,
            isFetching: false
          });
          default :
            return state;
    }
}

export default CardReadersReducer;
