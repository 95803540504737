import React from "react";

import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Container from '@material-ui/core/Container';
import Box from '@material-ui/core/Box';

import LoggedOutLayout from "../../../shared/layouts/logged-out/LoggedOutLayout";
import { rerouteToTenantLogin } from "../../../shared/utils/routing";

import '../../../shared/styles/login.css';

export default class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            tenant: ""
        };
    }

    handleChange = (e) => {
        this.setState({
            tenant: e.target.value
        });
    }

    render() {
        if (this.state.tenant) {
            rerouteToTenantLogin(this.state.tenant);
        }
        return (
            <LoggedOutLayout isLoggedIn={false}>
                <Container component="main" maxWidth="xs">
                    <div className="login-form">
                        <Box fontSize="h6.fontSize" m={1}>
                            Choisissez un client :
                        </Box> 
                        <FormControl style={{minWidth: 120}}>
                            <InputLabel id="tenant-label">Client</InputLabel>
                            <Select
                                labelId="tenant-label"
                                id="tenant-select"
                                value={this.state.tenant}
                                onChange={this.handleChange}
                            >
                                <MenuItem value={"itii-pdl"}>ITII PdL</MenuItem>
                                <MenuItem value={"test"}>Test</MenuItem>
                            </Select>
                        </FormControl>
                    </div>
                </Container>
            </LoggedOutLayout>
        );
    }
}