import { connect } from "react-redux";

import CourseSessionValidationView from "./CourseSessionValidationView";
import * as services from "../../../shared/redux/services";
import { saveSelectedCourseYearGroup } from "../../../shared/redux/actions";

const mapStateToProps = state => {
    return {
        isLoggedIn: state.LoginReducer.isLoggedIn,
        username: state.LoginReducer.username,
        courseYearGroups: state.SharedReducer.courseYearGroups,
        courseYearGroupsErrorMessage: state.SharedReducer.cygErrorMessage,
        selectedCourseYearGroup: state.SharedReducer.selectedCourseYearGroup,
        fetchedCourseSessions: state.CourseSessionsReducer.courseSessions,
        courseSessionsErrorMessage: state.CourseSessionsReducer.errorMessage,
        isFetchingCourseSessions: state.CourseSessionsReducer.isFetching,
        courseSessionsSaved: state.CourseSessionsReducer.courseSessionsSaved,
        courseSessionsUnsaved: state.CourseSessionsReducer.courseSessionsUnsaved,
        courseSessionsSaveErrorMessage: state.CourseSessionsReducer.courseSessionsSaveErrorMessage,
        isSendingCourseSessions: state.CourseSessionsReducer.isSending
    };
};

function mapDispatchToProps(dispatch) {
    return {
        sendGetCourseYearGroupsRequest: () => {
            dispatch(services.fetchCourseYearGroups());
        },
        sendGetCourseSessionsRequest: (courseYearGroup, startDate, endDate) => {
            dispatch(services.fetchCourseSessions(courseYearGroup, startDate, endDate));
        },
        sendCourseSessionsValidationRequest: (courseYearGroup, courseSessions) => {
            dispatch(services.sendCourseSessions(courseYearGroup, courseSessions));
        },
        selectCourseYearGroup: (courseYearGroup) => {
            dispatch(saveSelectedCourseYearGroup(courseYearGroup));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseSessionValidationView);