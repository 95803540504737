import React from 'react';
import ReactDOM from 'react-dom';
import { createStore, applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import thunkMiddleware from 'redux-thunk';

import AppContainer from './app/AppContainer';
import RootReducer from './shared/redux/RootReducer';
import { getSubdomain, getCurrentURLWithSubdomain } from './shared/utils/routing';

var subDomain = getSubdomain();
if (subDomain) {
    const store = createStore(RootReducer, applyMiddleware(thunkMiddleware));

    ReactDOM.render(
        <Provider store={store}>
            <AppContainer />
        </Provider>,
        document.getElementById('root')
    );
} else {
    /* TODO: Should redirect to the static website */
    window.location.replace(getCurrentURLWithSubdomain("picker"));
}