import React, { useState, useEffect } from "react";

import { makeStyles } from '@mui/styles';
import AddIcon from '@material-ui/icons/Add';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Tooltip from '@mui/material/Tooltip';

import { HelpButton, CourseYearGroupPicker, ActionableDialog } from '../../../shared/components';
import { CourseSessionValidationModal } from "./CourseSessionValidationModal";

const useStyles = makeStyles({
    root: {
        marginBottom: 15,
        textAlign: "left",
        paddingLeft: 10,
        display: "inline-flex",
        minWidth: "100%",
        alignItems: "center"
    },
    rightAlign: {
        marginLeft: "auto",
        paddingRight: 10
    }
});

export default function CourseSessionValidationTopBar(props) {
    const classes = useStyles();
    const [courseYearGroupIsSelected, setCourseYearGroupIsSelected] = useState(!!props.selectedCourseYearGroup);
    const [showValidateCourseSessionsModal, setShowValidateCourseSessionsModal] = useState(false);
    const [courseSessionsToSave, setCourseSessionsToSave] = useState([]);

    /* When course sessions are updated, refresh the ones that can be saved, meaning the ones without blocking errors and not confirmed. */
    useEffect(() => {
        if (!props.courseSessions) {
            setCourseSessionsToSave([]);
        }
        setCourseSessionsToSave(props.courseSessions
            .filter(courseSession => !courseSession.confirmed && (!courseSession.errors || !courseSession.errors.some(error => error.blocking))));
    }, [props.courseSessions]);

    /* Called when a course year group is selected */
    const handleCourseYearGroupSelection = (value) => {
        setCourseYearGroupIsSelected(true);
        props.handleCourseYearGroupSelection(value);
    };

    /* Called when user saves the course sessions */
    const handleValidateCourseSessions = () => {
        props.handleValidateCourseSessions(courseSessionsToSave);
        setShowValidateCourseSessionsModal(false);
    }

    /* Display warning message if any course sessions have at least one blocking error. */
    const displayBlockingErrorWarning = () => {
        return props.courseSessions && 
            props.courseSessions
                .flatMap(courseSession => courseSession.errors)
                .some(error => error.blocking);
    }

    const handleCloseValidateCourseSessionsModal = () => {
        setShowValidateCourseSessionsModal(false);
    };

    const handleOpenValidateCourseSessionsModal = () => {
        setShowValidateCourseSessionsModal(true);
    };

    const helperText = `Cette page permet de consulter et valider les cours qui ont bien eu lieu.
    Après avoir sélectionné une promotion, vous pouvez consulter les cours récupérés sur les emplois du temps de l'école,
    puis les valider.`;

    return (
        <Paper className={classes.root}>
            <HelpButton
                text={helperText}
            />
            <CourseYearGroupPicker
                selectedCourseYearGroup={props.selectedCourseYearGroup}
                courseYearGroups={props.courseYearGroups}
                handleCourseYearGroupSelection={handleCourseYearGroupSelection}
            />
            <div className={classes.rightAlign}>
                <Tooltip title={!courseYearGroupIsSelected ? "Sélectionnez la promotion pour laquelle vous souhaitez valider des cours." : ""}>
                    <span>
                        <Button variant="contained" color="primary" startIcon={<AddIcon />}
                            onClick={handleOpenValidateCourseSessionsModal}
                            disabled={!courseYearGroupIsSelected || courseSessionsToSave.length === 0}
                        >
                            Valider les cours affichés
                        </Button>
                    </span>
                </Tooltip>
            </div>
            { showValidateCourseSessionsModal ? 
                <CourseSessionValidationModal
                    open={showValidateCourseSessionsModal}
                    onClose={handleCloseValidateCourseSessionsModal}
                    onSavingCourseSessions={handleValidateCourseSessions}
                    displayBlockingErrorWarning={displayBlockingErrorWarning()}
                    courseSessionsToSave={courseSessionsToSave}
                />
                : null
            }
        </Paper>
    );
}