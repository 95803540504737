import * as actions from "../actions";
import * as endpoints from "../api";
import { simpleCallService, getDateString } from "../../utils/networking";

/** Send request to get absences for a given course year group  */
export function fetchAbsencesOfCourseYearGroup(courseYearGroup, isProcessed = false, isError = false) {
	return simpleCallService(
		endpoints.ABSENCE_GET_CONSECUTIVE_ENDPOINT + "?courseYearGroup=" + courseYearGroup + "&isProcessed=" + isProcessed + "&isError=" + isError,
		"GET",
		() => actions.fetchAbsencesOfCourseYearGroupRequest(courseYearGroup),
		actions.fetchAbsencesOfCourseYearGroupSuccess,
		actions.absenceHandlingRequestFailure,
		null,
		true
	);
}

/** Send request to get all possible absence motive types  */
export function fetchAbsenceMotiveTypes() {
	return simpleCallService(
		endpoints.ABSENCE_GET_MOTIVE_TYPES_ENDPOINT,
		"GET",
		actions.fetchAbsenceMotiveTypesRequest,
		actions.fetchAbsenceMotiveTypesSuccess,
		actions.absenceHandlingRequestFailure,
		null,
		true
	);
}

/** Send request to update motive of some absences  */
export function updateMotiveForAbsences(absenceIds, motiveType, complementaryNote = "", justified = false, documentFileName, documentFileLink) {
	return simpleCallService(
		endpoints.ABSENCE_UPDATE_MOTIVE_ENDPOINT,
		"POST",
		actions.updateAbsenceMotiveRequest,
		actions.updateAbsenceMotiveSuccess,
		actions.updateAbsenceMotiveFailure,
		{
			absenceIds: absenceIds,
			motiveType: motiveType,
			complementaryNote: complementaryNote,
			justified: justified,
			documentFileName: documentFileName,
			documentFileLink: documentFileLink
		},
		false
	);
}

/** Send request to cancel motive of some absences  */
export function cancelMotiveForAbsences(absenceIds) {
	return simpleCallService(
		endpoints.ABSENCE_REMOVE_MOTIVE_ENDPOINT,
		"POST",
		actions.cancelAbsenceMotiveRequest,
		actions.cancelAbsenceMotiveSuccess,
		actions.cancelAbsenceMotiveFailure,
		absenceIds,
		false
	);
}

/** Send request to cancel motive of some absences  */
export function createAbsence(studentId, startTime, endTime) {
	return simpleCallService(
		endpoints.ABSENCE_CREATE_ENDPOINT + "?studentId=" + studentId + "&startingDate=" + getDateString(startTime.toDate()) + "&endingDate=" + getDateString(endTime.toDate()),
		"POST",
		actions.createAbsenceRequest,
		actions.createAbsenceSuccess,
		actions.createAbsenceFailure,
		null,
		false
	);
}
