import { connect } from "react-redux";

import CardReaderTable from "./CardReaderTable";
import { fetchCardReaders } from "./redux/CardReadersServices";

const mapStateToProps = state => {
    return {
        isLoggedIn: state.LoginReducer.isLoggedIn,
        username: state.LoginReducer.username,
        cardReaders: state.CardReadersReducer.cardReaders,
        dateRequest: state.CardReadersReducer.dateRequest
    };
};

function mapDispatchToProps(dispatch) {
  return {
    sendCardReadersRequest : () => {
        dispatch(fetchCardReaders());
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CardReaderTable);
