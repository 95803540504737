import * as types from "../actions";

export const initialState = {
    courseYearGroup: undefined,
    cardDetails: undefined,
    cardId: undefined,
    attenderId: undefined,
    cardOwner: undefined,
    isLoading: false,
    errorMessage: undefined
};

export const CardDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_CARDETAILS_REQUEST:
            return Object.assign({}, state, {
                isLoading: true,
                errorMessage: undefined,
                courseYearGroup: action.courseYearGroup
            });
        case types.FETCH_CARDOWNER_REQUEST:
        case types.DISSOCIATE_CARD_REQUEST:
            return Object.assign({}, state, {
                isLoading: true,
                errorMessage: undefined,
                cardId: action.cardId
            });
        case types.AFFECT_CARD_REQUEST:
        case types.REAFFECT_CARD_REQUEST:
            return Object.assign({}, state, {
                isLoading: true,
                errorMessage: undefined,
                cardId: action.cardId,
                attenderId: action.attenderId
            });

        case types.FETCH_CARDETAILS_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                cardDetails: action.cardDetails
            });
        case types.FETCH_CARDOWNER_SUCCESS:
            return Object.assign({}, state, {
                isLoading: false,
                cardOwner: action.cardOwner
            });
        case types.DISSOCIATE_CARD_SUCCESS:
        case types.AFFECT_CARD_SUCCESS:
        case types.REAFFECT_CARD_SUCCESS:
            return Object.assign({}, state, { 
                isLoading: false
            });
        case types.FETCH_CARDOWNER_FAILURE:
        case types.DISSOCIATE_CARD_FAILURE:
        case types.AFFECT_CARD_FAILURE:
        case types.REAFFECT_CARD_FAILURE:
            return Object.assign({}, state, {
                isLoading: false,
                errorMessage: action.errorMessage
            });
        default:
            return state;
    }
}
