import React from "react";

import LoggedInLayout from "../../../shared/layouts/logged-in/LoggedInLayout";
import PaperSheet from "../../../shared/components/PaperSheet";
import DataView from "./DataView" ;

export default class DataManagementView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            type: "",
            text: ""
        };
    }

    handleTypeSelection(type) {
        this.setState({
            type: type
        });
    }

    handleChangeInTextField(text) {
        this.setState({
            text: text
        });
    }

    handleValidation() {
        this.props.sendDataRequest(this.state.type, this.state.text);
    }

    render() {
        return (
            <LoggedInLayout isLoggedIn={this.props.isLoggedIn} username={this.props.username}>
                <PaperSheet>
                  <DataView result={this.props.resultFetching} requestType={this.props.requestTypeFetching} fetchDataRequest={this.props.fetchDataRequest}/>
                </PaperSheet>
            </LoggedInLayout>
        );
    }
}
