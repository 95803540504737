import React from "react";
import moment from 'moment';
import 'moment/locale/fr';
import MaterialTable from '@material-table/core';
import { ExportCsv, ExportPdf } from "@material-table/exporters";

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import Tooltip from '@mui/material/Tooltip';

import DateStepper from '../../../shared/components/time-span/TimeSpan';
import { ABSENCE_VIEW_MODES, ABSENCE_STATUS_FILTERS } from '../../../shared/config';

const DAY_FORMAT = "dddd DD MMM YYYY";

// props.currentView & props.absences & isLoading & actions (delete, create, update)
export default function AbsencesHandlingTable(props) {
    /* Create actions based on current filter */
    const getActions = (statusFilter) => {
        const actions = [createAction('Ajouter une absence', 'Ajouter une absence', 'add', 'primary', props.handleCreateAbsence, true, !Array.isArray(props.absences))];
        if (statusFilter === ABSENCE_STATUS_FILTERS[0]) {
            actions.push(createAction('Appliquer un motif', 'Appliquer un motif aux absences selectionnées', 'edit', 'secondary', props.handleCreateMotive, false));
            actions.push(createAction('Supprimer absences', 'Supprimer les absences selectionnées', 'delete', 'secondary', props.handleDeleteAbsences, false));
        } else if (statusFilter === ABSENCE_STATUS_FILTERS[1]) {
            actions.push(createAction('Modifier un motif', 'Appliquer un nouveau motif aux absences selectionnées', 'edit', 'secondary', props.handleCreateMotive, false));
            actions.push(createAction('Supprimer absences', 'Supprimer les absences selectionnées', 'delete', 'secondary', props.handleDeleteAbsences, false));
        } else if (statusFilter === ABSENCE_STATUS_FILTERS[2]) {
            actions.push(createAction('Appliquer un motif', 'Appliquer un motif aux absences selectionnées', 'edit', 'secondary', props.handleCreateMotive, false));
            actions.push(createAction('Annuler la suppression', 'Annuler la suppression des absences selectionnées', 'edit', 'secondary', props.handleCancelMotive, false));
        }

        return actions;
    };

    /* Create columns based on current view and filter */
    const getColumns = (viewMode, statusFilter) => {
        const columns = [
            createColumn('Nom', 'lastName', null, 'asc'),
            createColumn('Prénom', 'firstName')
        ];
        if (viewMode === ABSENCE_VIEW_MODES[0]) {
            columns.push(createColumn('Intervalle', 'interval', null, null, rowData => <DateStepper startTime={rowData.startTime} endTime={rowData.endTime} />,
                (a , b) => a.startTime > b.startTime,
                (term, rowData) => (formatDate(rowData.startTime) + ' ' + formatDate(rowData.endTime)).indexOf(term) != -1));
            columns.push(createColumn("Nombre d'absences", 'numberOfAbsences', 'numeric'));
            columns.push(createColumn("Durée d'absence", 'duration'));
        } else if (viewMode === ABSENCE_VIEW_MODES[1]) {
            columns.push(createColumn('Date', 'day', 'date', null, null, (a, b) => moment(a.day, DAY_FORMAT) > moment(b.day, DAY_FORMAT)));
            columns.push(createColumn("Nombre d'absences", 'numberOfAbsences', 'numeric'));
            columns.push(createColumn("Durée d'absence", 'duration'));
        } else if (viewMode === ABSENCE_VIEW_MODES[2]) {
            columns.push(createColumn('Intervalle', 'interval', null, null, rowData => <DateStepper startTime={rowData.startTime} endTime={rowData.endTime} />,
                (a , b) => a.startTime > b.startTime,
                (term, rowData) => (formatDate(rowData.startTime) + ' ' + formatDate(rowData.endTime)).indexOf(term) != -1));
            columns.push(createColumn('Cours', 'discipline'));
        }

        if (statusFilter === ABSENCE_STATUS_FILTERS[1]) {
            columns.push(createColumn('Motif', 'motive'));
            columns.push(createColumn('Justificatif transmis', 'justified'));
            columns.push(createColumn('Commentaire', 'note'));
        } else if (statusFilter === ABSENCE_STATUS_FILTERS[2]) {
            columns.push(createColumn('Motif', 'motive'));
        }

        return columns;
    }

    /* Format and filter data based on current view and filter */
    const getData = (consecutiveAbsences, viewMode, statusFilter) => {
        if (!consecutiveAbsences) return [];

        let absences = [];
        if (viewMode === ABSENCE_VIEW_MODES[0]) {
            absences = consecutiveAbsences.map(absence => getRowDataFrom(absence));
        } else if (viewMode === ABSENCE_VIEW_MODES[1]) {
            absences = consecutiveAbsences
                .flatMap(absence => getDailyAbsences(absence))
                .map(absence => getRowDataFrom(absence));
        } else if (viewMode === ABSENCE_VIEW_MODES[2]) {
            absences = consecutiveAbsences
                .flatMap(absence => getSingleAbsences(absence))
                .map(absence => getRowDataFrom(absence));
        }

        if (statusFilter === ABSENCE_STATUS_FILTERS[0]) {
            absences = absences.filter(absence => !absence.processed);
        } else if (statusFilter === ABSENCE_STATUS_FILTERS[1]) {
            absences = absences.filter(absence => (absence.processed && !absence.deleted));
        } else if (statusFilter === ABSENCE_STATUS_FILTERS[2]) {
            absences = absences.filter(absence => (absence.processed && absence.deleted));
        }

        return absences;
    }

    /* Returns the content of a grouped row (like consecutive absences) */
    const getDetailPanel = (row) => {
        if (!row.rowData.absences) return null;

        const absences = row.rowData.absences.map(absence => absence.courseSession);
        return (
            <div style={{ padding: '15px' }}>
                <MaterialTable
                    columns={[
                        createColumn('Cours', 'discipline'),
                        createColumn('Intervalle', 'interval', null, 'asc', rowData => <DateStepper startTime={rowData.startTime} endTime={rowData.endTime} />, (a , b) => a.startTime > b.startTime)
                    ]}
                    data={absences}
                    options={{
                        pageSize: absences.length,
                        padding: 'dense'
                    }}
                    components={{
                        Toolbar: props => (null),
                        Pagination: props => (null)
                    }}
                />
            </div>
        )
    }

    return (
        <MaterialTable
            title={props.currentView.viewMode.name + " - Absences " + props.currentView.statusFilter}
            isLoading={props.isLoading}
            actions={getActions(props.currentView.statusFilter)}
            columns={getColumns(props.currentView.viewMode, props.currentView.statusFilter)}
            data={getData(props.absences, props.currentView.viewMode, props.currentView.statusFilter)}
            detailPanel={
                props.currentView.viewMode === ABSENCE_VIEW_MODES[2] ? [] : 
                [{
                    tooltip: 'Voir les cours',
                    render: rowData => {
                        return (
                            <div style={{ backgroundColor: '#e1e1e1' }}>
                                {getDetailPanel(rowData)}
                            </div>
                        )
                    }
                }]
            }
            options={{
                pageSize: 10,
                pageSizeOptions: [10, 50, 100],
                selection: true,
                exportMenu: [
                    {
                        label: "Exporter en PDF",
                        exportFunc: (cols, datas) => ExportPdf(cols, datas, "Absences de la Promotion"),
                    },
                    {
                        label: "Exporter en CSV",
                        exportFunc: (cols, datas) => ExportCsv(cols, datas, "Absences de la Promotion"),
                    },
                ],
            }}
            localization={{
                toolbar: {
                    nRowsSelected: '{0} ligne(s) sélectionnée(s)',
                    searchPlaceholder: 'Rechercher',
                    searchTooltip: 'Faire une recherche'
                },
                body: {
                    emptyDataSourceMessage: (Array.isArray(props.absences) ? "Aucune absence à afficher" : "Veuillez sélectionner une promotion")
                },
                pagination: {
                    labelRowsSelect: "lignes"
                }
            }}
            components={{
                Action: actionComponent
            }}

        />
    );
}

const createAction = (textButton, tooltip, icon, color, onClick, isFreeAction = false, isDisabled = false) => {
    return {
        textButton: textButton,
        tooltip: tooltip,
        icon: icon,
        color: color,
        onClick: onClick,
        isFreeAction: isFreeAction,
        disabled: isDisabled
    }
}

const createColumn = (title, field, type, defaultSort, render, customSort, customFilterAndSearch) => {
    return {
        title: title,
        field: field,
        type: type,
        defaultSort: defaultSort,
        render: render,
        customSort: customSort,
        customFilterAndSearch: customFilterAndSearch
    }
}

const actionComponent = (props) => {
    return (
        <Tooltip title={props.action.tooltip}>
            <Button
                sx={{ margin: "10px" }}
                variant="contained"
                onClick={(event) => props.action.onClick(props.data)}
                color={props.action.color}
                disabled={props.action.disabled}
            >
                <Icon>{props.action.icon}</Icon>
                {props.action.textButton}
            </Button>
        </Tooltip>
    );
}

const getDailyAbsences = (consecutiveAbsence) => {
    const dayToAbsences = new Map();
    consecutiveAbsence.absences.forEach(absence => {
        const day = moment(absence.courseSession.startTime).startOf('day').format();
        if (dayToAbsences.get(day)) {
            dayToAbsences.get(day).push(absence);
        } else {
            dayToAbsences.set(day, [absence]);
        }
    });

    const dailyAbsences = [];
    dayToAbsences.forEach((value, key) => {
        const firstAbsence = value[0]; // We can take the first absence because all of them are supposed to have the same student and motive.
        dailyAbsences.push({
            studentFirstName: firstAbsence.studentFirstName,
            studentLastName: firstAbsence.studentLastName,
            motive: firstAbsence.motive,
            complementaryNote: firstAbsence.complementaryNote,
            processed: firstAbsence.processed,
            day: moment(key).format(DAY_FORMAT),
            durationInMinute: value.map(absence => absence.absenceDurationInMinute).reduce((acc, duration) => acc + duration, 0),
            absences: value
        });
    });
    return dailyAbsences;
}

const getSingleAbsences = (consecutiveAbsence) => {
    const singleAbsences = [];
    consecutiveAbsence.absences.forEach(absence => {
        singleAbsences.push({
            studentFirstName: absence.studentFirstName,
            studentLastName: absence.studentLastName,
            motive: absence.motive,
            complementaryNote: absence.complementaryNote,
            processed: absence.processed,
            startTime: absence.courseSession.startTime,
            endTime: absence.courseSession.endTime,
            durationInMinute: absence.absenceDurationInMinute,
            discipline: absence.courseSession.discipline,
            absenceId: absence.absenceId
        });
    });
    return singleAbsences;
}

const getRowDataFrom = (rootAbsence) => {
    const coreRow = {
        lastName: rootAbsence.studentLastName,
        firstName: rootAbsence.studentFirstName,
        startTime: rootAbsence.startTime,   // Null for daily mode
        endTime: rootAbsence.endTime,       // Null for daily mode
        duration: Math.floor(rootAbsence.durationInMinute / 60) + "h" + (rootAbsence.durationInMinute % 60 < 10 ? "0" : "") + rootAbsence.durationInMinute % 60,
        id: (rootAbsence.absenceId ? rootAbsence.absenceId : ""),
        discipline: (rootAbsence.discipline ? rootAbsence.discipline : ""),
        day: (rootAbsence.day ? rootAbsence.day : "")
    }
    if (rootAbsence.absences) {
        return {
            ...coreRow,
            numberOfAbsences: (rootAbsence.absences ? rootAbsence.absences.length : 1),
            absences: rootAbsence.absences,
            processed:  (rootAbsence.absences[0].processed ? rootAbsence.absences[0].processed : false),
            motive:     (rootAbsence.absences[0].motive ? rootAbsence.absences[0].motive.typeDescription : ""),
            note:       (rootAbsence.absences[0].motive ? rootAbsence.absences[0].motive.complementaryNote : ""),
            deleted:    (rootAbsence.absences[0].motive ? rootAbsence.absences[0].motive.error : false),
            justified:  (rootAbsence.absences[0].motive ? (rootAbsence.absences[0].motive.justified ? 'oui' : 'non') : 'non')
        }
    } else {
        return {
            ...coreRow,
            numberOfAbsences: 1,
            processed: (rootAbsence.processed ? rootAbsence.processed : false),
            motive: (rootAbsence.motive ? rootAbsence.motive.typeDescription : ""),
            note: (rootAbsence.motive ? rootAbsence.motive.complementaryNote : ""),
            deleted: (rootAbsence.motive ? rootAbsence.motive.error : false)
        }
    }
}

const formatDate = (date) => {
	return moment(date).format("dddd DD MMM YYYY  -  HH:mm");
}