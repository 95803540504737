import React from "react";

import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton  from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

export function ResponsiveDialog(props) {
    const { onClose, open, content, maxWidth, fullWidth } = props;
      return (
          <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open} fullWidth={fullWidth} maxWidth={maxWidth}>
              <DialogTitle id="simple-dialog-title">{content.title}
                  <IconButton aria-label="close" onClick={onClose} style={{float:"right", padding:"0px"}}>
                      <CloseIcon />
                  </IconButton>
              </DialogTitle>
              <DialogContent dividers>
                  {content.text}
              </DialogContent>
              {(content.action !== undefined) &&
              (<DialogActions>
                  {content.action}
              </DialogActions>)
              }
          </Dialog>
      );
}
