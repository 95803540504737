import React, { useEffect, useState } from "react";
import { makeStyles } from '@mui/styles';

import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

import LoggedInLayout from "../../../shared/layouts/logged-in/LoggedInLayout";
import { CourseYearGroupPicker, StudentPicker } from '../../../shared/components';

const useStyles = makeStyles({
    root: {
        marginBottom: 20,
        textAlign: "left",
        padding: 20,
        display: "inline-flex",
        minWidth: "100%",
        alignItems: "center"
    }
});

const cardDetailToStudent = (cardDetail) => {
    return {
        studentFirstName: cardDetail.firstName,
        studentLastName: cardDetail.lastName,
        studentId: cardDetail.id
    };
}

export default function CardManagementPageView(props) {
    const classes = useStyles();
    const { sendCourseYearGroupsRequest, sendCourseYearGroupCardDetailsRequest } = props;
    const [selectedStudent, setSelectedStudent] = useState('');
    const [cardToSearch, setCardToSearch] = useState('');
    const [cardToAssociate, setCardToAssociate] = useState('');
    const [selectedCourseYearGroup, setSelectedCourseYearGroup] = useState('');

    /* Fetching Available course year groups */
    useEffect(() => {
        sendCourseYearGroupsRequest();
    }, [sendCourseYearGroupsRequest]);

    /* Fetching students and card details */
    const handleCourseYearGroupSelection = (newCourseYearGroup) => {
        setSelectedCourseYearGroup(newCourseYearGroup);
        props.sendCardDetailsRequest(newCourseYearGroup);
    }

    const handleStudentSelection = (student) => {
        setSelectedStudent(student);
    }

    /* Fetching card owner (if any) */
    const handleSearchCardOwner = () => {
        if (cardToSearch) {
            props.sendCardOwnerRequest(cardToSearch);
        }
    }

    const handleAssociateCard = () => {
        if (cardToAssociate) {
            props.sendAffectCardRequest(cardToAssociate, selectedStudent);
        }
    }

    /* Dissociate card */
    const handleDissociateCard = (cardId) => {
        props.sendDissociateCardRequest(cardId);
    }

    const handleCardToSearchChange = (event) => {
        setCardToSearch(event.target.value);
    }
    const handleCardToAssociateChange = (event) => {
        setCardToAssociate(event.target.value);
    }

    return (
        <LoggedInLayout isLoggedIn={props.isLoggedIn} username={props.username}>
            <Paper className={classes.root}>
                <Stack>
                    <Typography variant="h6" gutterBottom>
                        Trouver la personne associée à une carte
                    </Typography>
                    <div>
                        <TextField id="card-to-search" label="Numéro de la carte" variant="standard" style={{ marginRight: 10 }}
                            value={cardToSearch}
                            onChange={handleCardToSearchChange}
                        />
                        <Button variant="contained" onClick={handleSearchCardOwner}>
                            Rechercher
                        </Button>
                    </div>
                    { props.cardOwner ?
                        !props.cardOwner.lastName
                            ? <Typography>Personne n'est associée à cette carte.</Typography>
                            : <div style= {{marginTop: 15 }}>
                                <TextField id="card-owner-last-name" label="Nom" style={{ marginRight: 10 }} value={props.cardOwner.lastName} disabled={true} />
                                <TextField id="card-owner-first-name" label="Prénom" style={{ marginRight: 10 }} value={props.cardOwner.firstName} disabled={true} />
                                <TextField id="card-owner-cyg-name" label="Promotion" style={{ marginRight: 10 }} value={props.cardOwner.courseYearGroupName} disabled={true} />
                            </div>
                        : null
                    }
                    <Typography variant="h6" gutterBottom style={{ marginTop: 15 }}>
                        Consulter et modifier les cartes d'une promotion
                    </Typography>
                    <div style={{ textAlign: "left", marginRight: "20px" }}>
                        <CourseYearGroupPicker
                            courseYearGroups={props.courseYearGroups}
                            handleCourseYearGroupSelection={handleCourseYearGroupSelection}
                        />
                        <StudentPicker
                            students={props.cardDetails ? props.cardDetails.map(cardDetailToStudent) : []}
                            handleStudentSelection={handleStudentSelection}
                            disabled={!selectedCourseYearGroup}
                        />
                    </div>
                    {selectedStudent ?
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                            {props.cardDetails
                                .filter((cardDetail) => cardDetail.id === selectedStudent)
                                .map((cardDetail) => {
                                    return cardDetail.cardIds.map((singleCard) => {
                                        return (
                                            <ListItem
                                                key={singleCard}
                                            >
                                                <div>
                                                    <TextField id="card-id" label="Numéro de carte" style={{ marginRight: 10 }} value={singleCard} disabled={true} />
                                                    <Button variant="contained" style={{ marginRight: 10 }} onClick={() => handleDissociateCard(singleCard)}>Supprimer</Button>
                                                    <Button variant="contained" disabled={true}>Réaffecter</Button>
                                                </div>
                                            </ListItem>
                                        );
                                });
                            })}
                        </List>
                
                    : null}
                    
                    <Typography variant="h6" gutterBottom style={{ marginTop: 15 }}>
                        Associer une nouvelle carte à l'étudiant sélectionné
                    </Typography>
                    <div>
                        <TextField id="card-to-associate" label="Numéro de la carte" variant="standard" style={{ marginRight: 10 }}
                            disabled={!selectedStudent}
                            value={cardToAssociate}
                            onChange={handleCardToAssociateChange}
                        />
                        <Button variant="contained" disabled={!selectedStudent}
                            onClick={handleAssociateCard}>
                                Associer
                        </Button>
                    </div>
                </Stack>
            </Paper>
        </LoggedInLayout>
    );
}