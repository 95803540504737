import React from "react";
import { Typography, Button, Tabs, TextField, Tab, Paper } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import LoggedInLayout from "../../../shared/layouts/logged-in/LoggedInLayout";
import RealTimeAttendancyViewContainer from "../real-time-attendancy-page/RealTimeAttendancyViewContainer";


const useStyles = makeStyles({
    root: {
        marginBottom: 15
    },
    heading: {
        flexBasis: '33.33%',
        flexShrink: 0,
    },
    headingFuture: {
        flexBasis: '33.33%',
        flexShrink: 0,
        color: "grey"
    },
    secondaryHeadingDone: {
        color: "grey",
        fontStyle: "italic"
    },
    text: {
        textAlign: "justify",
        margin: "20px 40px 20px 40px"
    },
    button: {
        marginRight: "10px"
    }
});


export default function Dashboard(props) {
    const classes = useStyles();
    const [value, setValue] = React.useState(0);
    const [expanded, setExpanded] = React.useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleAccordionChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const displayTestScreen = false;
    if (displayTestScreen) {
        return (<RealTimeAttendancyViewContainer />);
    } else {
        return (
            <LoggedInLayout isLoggedIn={props.isLoggedIn} username={props.username}>
                <Paper className={classes.root}>
                    <Tabs
                        value={value}
                        onChange={handleChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab label="Casuels 2021" />
                        <Tab label="Casuels 2022" />
                        <Tab label="Casuels 2023" />
                    </Tabs>
                </Paper>
                <Accordion expanded={expanded === 'panel1'} onChange={handleAccordionChange('panel1')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1bh-content"
                        id="panel1bh-header"
                    >
                        <Typography className={classes.heading}>Premier semestre - Elève</Typography>
                        <Typography className={classes.secondaryHeadingDone}>Envoyé le 12/01/2021</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography className={classes.text}>
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                            <br /><br />Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse malesuada lacus ex, sit amet blandit leo lobortis eget.
                            <br /><br />Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur?
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel2'} onChange={handleAccordionChange('panel2')}>
                    <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel2bh-content"
                        id="panel2bh-header"
                    >
                        <Typography className={classes.heading}>Premier Semestre - Tuteur</Typography>
                        <Typography className={classes.secondaryHeadingDone}>En cours d'écriture</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <div style={{width: "100%", margin: "20px" }}>
                            <div style = {{ margin: "20px" }}>
                                <TextField multiline fullWidth rows={5}>

                                </TextField>
                            </div>
                            <div style={{ display: "inline", margin: "10px" }}>
                                <Button color='secondary' variant="contained" className={classes.button}>Sauvegarder</Button>

                                <Button color='secondary' variant="contained">Envoyer</Button>
                            </div>
                        </div>

                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel3'} >
                    <AccordionSummary
                        aria-controls="panel3bh-content"
                        id="panel3bh-header"
                    >
                        <Typography className={classes.headingFuture}>Second Semestre - Elève</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros,
                            vitae egestas augue. Duis vel est augue.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
                <Accordion expanded={expanded === 'panel4'} >
                    <AccordionSummary
                        aria-controls="panel4bh-content"
                        id="panel4bh-header"
                    >
                        <Typography className={classes.headingFuture}>Second Semestre - Tuteur</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                        <Typography>
                            Nunc vitae orci ultricies, auctor nunc in, volutpat nisl. Integer sit amet egestas eros,
                            vitae egestas augue. Duis vel est augue.
                        </Typography>
                    </AccordionDetails>
                </Accordion>
            </LoggedInLayout>
        );
    }
}