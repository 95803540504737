import { connect } from "react-redux";

import Dashboard from "./Dashboard";

const mapStateToProps = state => {
    return {
        isLoggedIn: state.LoginReducer.isLoggedIn,
        username: state.LoginReducer.username
    };
};

export default connect(mapStateToProps, undefined)(Dashboard);