import { connect } from "react-redux";

import RealTimeAttendancyView from "./RealTimeAttendancyView";
import { fetchCourseYearGroups, fetchStudentsOfCourseYearGroup, fetchRealTimeAttendancy, createAttendanceRecord } from "../../../shared/redux/services";

const mapStateToProps = state => {
    return {
        isLoggedIn: state.LoginReducer.isLoggedIn,
        username: state.LoginReducer.username,
        courseYearGroups: state.SharedReducer.courseYearGroups,
        courseYearGroupsErrorMessage: state.SharedReducer.cygErrorMessage,
        realtimeAttendancy: state.RealTimeAttendancyReducer.realtimeAttendancy,
        realtimeAttendancyErrorMessage: state.RealTimeAttendancyReducer.errorMessage,
        isFetching: state.RealTimeAttendancyReducer.isFetching,
        students: state.SharedReducer.students,
        studentsErrorMessage: state.SharedReducer.studentsErrorMessage,
        isCreatingAttendanceRecord: state.RealTimeAttendancyReducer.isCreatingAttendanceRecord,
        attendanceRecordCreated: state.RealTimeAttendancyReducer.attendanceRecordCreated,
        attendanceRecordCreationErrorMessage: state.RealTimeAttendancyReducer.attendanceRecordCreationErrorMessage
    };
};

function mapDispatchToProps(dispatch) {
    return {
        sendCourseYearGroupsRequest: () => {
            dispatch(fetchCourseYearGroups());
        },
        sendRetailTimeAttendancyRequest: (courseYearGroup, dateInTheWeek, withSchedule) => {
            dispatch(fetchRealTimeAttendancy(courseYearGroup, dateInTheWeek, withSchedule));
        },
        sendFetchStudentsRequest: (courseYearGroup) => {
            dispatch(fetchStudentsOfCourseYearGroup(courseYearGroup));
        },
        sendCreateAttendanceRecordRequest: (studentIds, datetime) => {
            dispatch(createAttendanceRecord(studentIds, datetime));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(RealTimeAttendancyView);