import * as types from "../actions";

export const initialState = {
    courseYearGroup: undefined,
    absences: undefined,
    motiveTypes: undefined,
    errorMessage: undefined,
    isLoading: false,
    isWorflowLoading: false,
    isWorkflowSuccess: false,
    isWorkflowFailure: false
};

export const AbsencesReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_ABSENCESOFCOURSEYEARGROUP_REQUEST:
            return Object.assign({}, state, {
                courseYearGroup: action.courseYearGroup,
                absences: undefined,
                isLoading: true,
                errorMessage: undefined,
                isWorflowLoading: false,
                isWorkflowSuccess: false,
                isWorkflowFailure: false
            });
        case types.FETCH_ABSENCESOFCOURSEYEARGROUP_SUCCESS:
            return Object.assign({}, state, {
                absences: action.absences,
                isLoading: false,
                errorMessage: undefined
            });
        case types.ABSENCE_HANDLING_REQUEST_FAILURE:
            return Object.assign({}, state, {
                isLoading: false,
                errorMessage: action.errorMessage
            });
        case types.FETCH_ABSENCEMOTIVETYPES_REQUEST:
            return Object.assign({}, state, {
                isLoading: true
            });
        case types.FETCH_ABSENCEMOTIVETYPES_SUCCESS:
            return Object.assign({}, state, {
                motiveTypes: action.motiveTypes,
                isLoading: false
            });
        case types.UPDATE_ABSENCEMOTIVE_REQUEST:
        case types.CANCEL_ABSENCEMOTIVE_REQUEST:
        case types.CREATE_ABSENCE_REQUEST: 
            return Object.assign({}, state, {
                isWorflowLoading: true
            });
        case types.UPDATE_ABSENCEMOTIVE_SUCCESS:
        case types.CANCEL_ABSENCEMOTIVE_SUCCESS:
        case types.CREATE_ABSENCE_SUCCESS:
            return Object.assign({}, state, {
                isWorflowLoading: false,
                isWorkflowSuccess: true
            });
        case types.UPDATE_ABSENCEMOTIVE_FAILURE:
        case types.CANCEL_ABSENCEMOTIVE_FAILURE:
        case types.CREATE_ABSENCE_FAILURE:
            return Object.assign({}, state, {
                isWorflowLoading: false,
                isWorkflowFailure: true
            });
        default:
            return state;
    }
}