import React from "react";
import 'moment/locale/fr';
import MaterialTable from '@material-table/core';
import { ExportCsv, ExportPdf } from "@material-table/exporters";

const DAY_FORMAT = "dddd DD MMM YYYY";

export default function GroupAttendanceTab(props) {
    return (
        <MaterialTable
            columns={[
                { title: 'Nom', field: 'studentLastName' },
                { title: 'Prénom', field: 'studentFirstName' },
                { title: 'Nombre d\'absences', field: 'numberOfAbsences', type: 'numeric' },
                { title: 'Nombre d\'absences sans justificatif', field: 'unjustifiedAbsences', type: 'numeric' },
                { title: 'Durée totale des absences (minutes)', field: 'totalAbsenceTime', type: 'numeric' },
                { title: 'Durée totale des absences sans justificatif (minutes)', field: 'totalAbsenceTime', type: 'numeric' }
            ]}
            data={props.stats}
            title={"Suivi des étudiants"}
            isLoading={props.isLoading}
            options={{
                search: false,
                exportMenu: [
                    {
                        label: "Exporter en PDF",
                        exportFunc: (cols, datas) => ExportPdf(cols, datas, "Absences de la promotion"),
                    },
                    {
                        label: "Exporter en CSV",
                        exportFunc: (cols, datas) => ExportCsv(cols, datas, "Absences de la promotion"),
                    },
                ],
                pageSize: 10,
                pageSizeOptions: [10, 30, 50]
            }}
            localization={{
                toolbar: {
                    nRowsSelected: '{0} ligne(s) sélectionnée(s)'
                },
                body: {
                    emptyDataSourceMessage: "Aucune promotion sélectionnée"
                },
                pagination: {
                    labelRowsSelect: "lignes"
                }
            }}
        />
    );
}