import React, { useState, useEffect } from "react";
import moment from 'moment';

import Alert from '@mui/material/Alert';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import DateTimePicker from '@mui/lab/DateTimePicker';
import AdapterMoment from '@mui/lab/AdapterMoment';
import LoadingButton from '@mui/lab/LoadingButton';

import { ActionableDialog } from '../../../../shared/components';

export function CreateAbsenceModal(props) {
    const [hasSentRequest, setHasSentRequest] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState('');
    const [selectedStartTime, setSelectedStartTime] = useState(moment());
    const [selectedEndTime, setSelectedEndTime] = useState(moment());
    const [hasFetchedStudents, setHasFetchedStudents] = useState(false);

    const { fetchStudentsRequest } = props;

    /* Fetching students of course year group */
    useEffect(() => {
        if (!hasFetchedStudents) {
            setHasFetchedStudents(true);
            fetchStudentsRequest();
        }
    }, [hasFetchedStudents, setHasFetchedStudents, fetchStudentsRequest]);

    const getContent = () => {
        return {
            title: "Créer une absence",
            text: getSelect()
        }
    }

    const handleStudentChange = (e) => {
        setSelectedStudent(e.target.value);
    }

    const handleStartTimeChange = (newValue) => {
        setSelectedStartTime(newValue);
    }

    const handleEndTimeChange = (newValue) => {
        setSelectedEndTime(newValue);
    }

    const handleSendRequest = () => {
        if (selectedStudent) {
            setHasSentRequest(true);
            props.sendRequest(selectedStudent.studentId, selectedStartTime, selectedEndTime);
        }
    }
    
    const getSelect = () => {
        return (
            <div style={{ display: 'flex', flexDirection: 'column', gap: '20px', maxWidth: '300px'}}>
                <Typography>
                    Veuillez sélectionner un étudiant, une date de début et de fin de l'absence, puis confirmer:
                </Typography>
                <FormControl variant="standard">
                    <InputLabel id="student-select-label">Etudiant absent</InputLabel>
                    <Select
                        labelId="student-select-label"
                        id="student-select"
                        value={selectedStudent}
                        onChange={handleStudentChange}
                        label="Etudiant absent"
                    >
                        { props.students ?
                            props.students
                                .sort((firstStudent, secondStudent) => firstStudent.studentLastName.localeCompare(secondStudent.studentLastName))
                                .map((student, index) => {
                                    return (
                                        <MenuItem key={index} value={student}>
                                            {student.studentLastName + " " + student.studentFirstName}
                                        </MenuItem>
                                    );
                                })
                            : null
                        }
                    </Select>
                    <div style={{ marginTop: '20px', minWidth: '300px' }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                label="Date de début d'absence"
                                value={selectedStartTime}
                                onChange={handleStartTimeChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                    </LocalizationProvider>
                    </div>
                    <div style={{ marginTop: '20px' }}>
                        <LocalizationProvider dateAdapter={AdapterMoment}>
                            <DateTimePicker
                                label="Date de fin d'absence"
                                value={selectedEndTime}
                                onChange={handleEndTimeChange}
                                renderInput={(params) => <TextField {...params} />}
                            />
                        </LocalizationProvider>
                    </div>
                    
                </FormControl>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    disabled={!selectedStudent || props.success || props.failure}
                    onClick={handleSendRequest}
                    loading={props.isLoading}
                >
                    Créer une absence
                </LoadingButton>
                { props.success
                    ? <Alert severity="success">L'absence a bien été créée.</Alert>
                    : null
                }
                { props.failure
                    ? <Alert severity="error">Une erreur s'est produite, veuillez contacter un administrateur à admin@alumneo.fr.</Alert>
                    : null
                }
            </div>
        );
    }

    return (
        <ActionableDialog
            open={props.open}
            onClose={() => props.onClose(hasSentRequest)}
            content={getContent()}
        />
    );
}
