import React from "react";

export default class LoggedOutLayout extends React.Component {
    render() {
        return (
            <div className="itii-logged-out">
                {this.props.children}
            </div>
        );
    }
}