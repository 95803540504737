import * as actions from "../actions";
import * as endpoints from "../api";
import { simpleCallService, getDateString } from "../../utils/networking";

/** Send request to get real time attendancy for a given period  */
export function fetchRealTimeAttendancy(courseYearGroup, dateInTheWeek, withSchedule) {
    return simpleCallService(
		(withSchedule ? endpoints.WEEKLY_ATTENANCY_WITH_SCHEDULE_ENDPOINT : endpoints.WEEKLY_ATTENDANCY_ENDPOINT)
            + "?courseYearGroup=" + courseYearGroup
            + "&dateInTheWeek=" + getDateString(dateInTheWeek)
            + "&currentDate=" + getDateString(new Date()),
		"GET",
		actions.feachRealTimeAttendancyRequest,
		actions.fetchRealtimeAttendancySuccess,
		actions.fetchRealtimeAttendancyFailure,
		null,
		true
	);
}

/*         const dateInTheWeekString = moment(dateInTheWeek).add(-1 * dateInTheWeek.getTimezoneOffset(), 'm').toDate().toISOString();
        const currentDate = new Date();
        const currentDateString = moment(currentDate).add(-1 * currentDate.getTimezoneOffset() - 1, 'm').toDate().toISOString();

export function getDateString(date) {
    return moment(date).add(-1 * date.getTimezoneOffset(), 'm').toDate().toISOString();
}
*/ 

/* Create attendance record for one or multiple students */
export function createAttendanceRecord(studentIds, datetime) {
    return simpleCallService(
		endpoints.ATTENDANCE_RECORD_CREATE_ENDPOINT + "?&attendanceDate=" + getDateString(datetime.toDate()),
		"PUT",
		actions.createAttendanceRecordRequest,
		actions.createAttendanceRecordSuccess,
		actions.createAttendanceRecordFailure,
		studentIds,
		false
	);
}

/** Send request to get attendance records of a requesting student. */
export function fetchSelfAttendanceRecords() {
    return simpleCallService(
		endpoints.SELF_ATTENDANCE_RECORDS_ENDPOINT,
		"GET",
		actions.fetchStudentAttendanceRecordsRequest,
		actions.fetchStudentAttendanceRecordsSuccess,
		actions.fetchStudentAttendanceRecordsFailure,
		null,
		true
	);
}