import React, { useState } from "react";
import moment from 'moment';

import { makeStyles } from '@mui/styles';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import DateRangePicker from '@mui/lab/DateRangePicker';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import AdapterMoment from '@mui/lab/AdapterMoment';

import { HelpButton, CourseYearGroupPicker, StudentPicker } from '../../../shared/components';

const useStyles = makeStyles({
    root: {
        marginBottom: 15,
        textAlign: "left",
        paddingLeft: 10,
        display: "inline-flex",
        minWidth: "100%",
        alignItems: "center"
    }
});

const getFirstOfLastSeptember = () => {
    const firstSeptemberOfThisYear = moment(moment().year() + "0901", "YYYYMMDD");
    if (moment().month() > 7) {
        return firstSeptemberOfThisYear;
    } else {
        return firstSeptemberOfThisYear.subtract(1, 'years');
    }
}

export default function StudentsAttendanceMonitoringTopBar(props) {
    const classes = useStyles();
    const [value, setValue] = useState([getFirstOfLastSeptember(), moment()]);
    const [selectedCourseYearGroup, setSelectedCourseYearGroup] = useState('');

    const handleViewModeChange = (isGroupTheNewView) => {
        if (isGroupTheNewView != props.isGroupViewSelected) {
            props.handleChangeView();
        }
    }

    const handleCourseYearGroupSelection = (newCourseYearGroup) => {
        if (!props.isGroupViewSelected) props.handleChangeView();
        setSelectedCourseYearGroup(newCourseYearGroup);
        props.sendCourseYearGroupStatsRequest(newCourseYearGroup, value[0], value[1]);
    }

    const handleStudentSelection = (student) => {
        if (props.isGroupViewSelected) props.handleChangeView();
        props.handleStudentSelection(student);
        props.sendStudentCourseSessionsRequest(student, value[0], value[1]);
    }

    const handleChangeInDateRange = (newValue) => {
        setValue(newValue);
        if (selectedCourseYearGroup) {
            props.sendCourseYearGroupStatsRequest(selectedCourseYearGroup, newValue[0], newValue[1]);
        }
        if (props.selectedStudent) {
            props.sendStudentCourseSessionsRequest(props.selectedStudent, newValue[0], newValue[1]);
        }
    }

    const helperText = `Cette page permet de suivre le niveau de présence des étudiants. Seules les absences ayant été traitées sont prises en compte.`;

    return (
        <Paper className={classes.root}>
            <HelpButton
                text={helperText}
            />
            <div style={{ textAlign: "left", marginRight: "20px" }}>
                <Button
                    variant="contained"
                    color={props.isGroupViewSelected ? "primary" : "lightGrey"}
                    onClick={() => handleViewModeChange(true)}
                    sx={{ marginLeft: 1 }}
                >
                    {"Vue Promotion"}
                </Button>
                <Button
                    variant="contained"
                    color={!props.isGroupViewSelected ? "primary" : "lightGrey"}
                    onClick={() => handleViewModeChange(false)}
                    sx={{ marginLeft: 1 }}
                    disabled={!selectedCourseYearGroup || !props.selectedStudent}
                >
                    {"Vue Etudiant"}
                </Button>
            </div>
            <div style={{ textAlign: "left", marginRight: "20px" }}>
                <CourseYearGroupPicker
                    courseYearGroups={props.courseYearGroups}
                    handleCourseYearGroupSelection={handleCourseYearGroupSelection}
                />
                <StudentPicker
                    students={props.students}
                    handleStudentSelection={handleStudentSelection}
                    disabled={!selectedCourseYearGroup}
                />
            </div>
            <div style={{ marginLeft: "auto", marginRight: "20px" }}>
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateRangePicker
                        startText="Début de la période"
                        endText="Fin de la période"
                        value={value}
                        onChange={handleChangeInDateRange}
                        renderInput={(startProps, endProps) => (
                            <React.Fragment>
                                <TextField {...startProps} />
                                <Box sx={{ mx: 1 }}> à </Box>
                                <TextField {...endProps} />
                            </React.Fragment>
                        )}
                    />
                </LocalizationProvider>
            </div>
        </Paper>
    );
}