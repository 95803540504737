import { combineReducers } from 'redux';

import { AbsencesReducer, SharedReducer, StudentsAttendanceStatsReducer, RealTimeAttendancyReducer, CourseSessionsReducer, LoginReducer, PasswordResetReducer, CardDetailsReducer } from './reducers';

import CardReadersReducer from '../../pages/admin-pages/card-readers-monitoring-page/redux/CardReadersReducer';
import DataManagementReducer from '../../pages/admin-pages/data-management-page/redux/DataManagementReducer';
import DataVisualisationReducer from '../../pages/admin-pages/data-management-page/redux/DataVisualisationReducer';
import ServerManagementReducer from '../../pages/admin-pages/server-management-page/redux/ServerManagementReducer';
import AttendanceCertificatesReducer from '../../pages/unused-pages/attendance-certificates-management-page/redux/AttendanceCertificatesReducer';

export default combineReducers({
	SharedReducer,
	AbsencesReducer,
	StudentsAttendanceStatsReducer,
	RealTimeAttendancyReducer,
	CourseSessionsReducer,
	CardReadersReducer,
	DataManagementReducer,
	DataVisualisationReducer,
	LoginReducer,
	ServerManagementReducer,
	AttendanceCertificatesReducer,
	PasswordResetReducer,
	CardDetailsReducer
});
