import React from 'react';
import { Route, BrowserRouter, Routes, Navigate } from 'react-router-dom';

import { AVAILABLE_FEATURES, DEFAULT_HOME_PAGE, DEFAULT_HOME_STUDENT_PAGE, TENANTS_LIST, LOGIN_ROUTE, RESET_PASSWORD_ROUTE } from "../shared/config";
import LoginContainer from "../pages/external-pages/login-page/LoginContainer";
import ResetPasswordViewContainer from '../pages/external-pages/reset-password-page/ResetPasswordViewContainer';
import TenantPicker from "../pages/external-pages/tenant-picker-page/TenantPicker";
import { getSubdomain } from "../shared/utils/routing";

const AppRoutes = () => {
	const subdomain = getSubdomain();
	/* If there is no subdomain (should not happened here but still) or if not a tenant, redirect to TenantPicker. */
	if (!subdomain || !TENANTS_LIST.includes(subdomain)) {
		return (<TenantPicker />);
	} else {
		localStorage.setItem('tenant', subdomain);
		return (
			<BrowserRouter>
				<Routes>
					<Route path={'/' + LOGIN_ROUTE} element={<LoginContainer />} key={0} />
					<Route path={'/' + RESET_PASSWORD_ROUTE} element={<ResetPasswordViewContainer />} key={1} />
					<Route path={'/'} element={<RequireAuth><DEFAULT_HOME_PAGE /></RequireAuth>} key={2} />
					<Route path={'/student'} element={<RequireAuth><DEFAULT_HOME_STUDENT_PAGE /></RequireAuth>} key={3} />
					
					{Object.entries(AVAILABLE_FEATURES).map(([key, feature]) => {
						if (feature.active) {
							return (
								<Route path={'/' + feature.url} element={<RequireAuth><feature.container /></RequireAuth>} key = {4 + key} />
							);
						} else {
							return null;
						}
					})}
				</Routes>
			</BrowserRouter>
		)
	}
};

const RequireAuth = ({children}) => {
	if (!localStorage.token) {
		return <Navigate to={'/' + LOGIN_ROUTE} replace />;
	} else {
		return children;
	}
}

export default AppRoutes;

/*     useEffect()
    if (!localStorage.features || localStorage.features !== "STUDENT_ACCESS") {
        const navigate = useNavigate();
        navigate("/");
    }
	*/
