import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import Link from '@mui/material/Link';


import LoggedOutLayout from "../../../shared/layouts/logged-out/LoggedOutLayout";

import '../../../shared/styles/login.css';

export default function ResetPasswordView(props) {
    const [password, setPassword] = useState("");
    const [passwordVerif, setPasswordVerif] = useState("");
    const [passwordsAreDifferent, setPasswordsAreDifferent] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const [errorMessage, setErrorMessage] = useState("");

    const handlePasswordChange = (e) => {
        setPassword(e.target.value);
    }

    const handlePasswordVerifChange = (e) => {
        setPasswordVerif(e.target.value);
    }

    const handleSubmit = (e) => {
        const token = searchParams.get("token");
        if (!token) {
            setErrorMessage("Il semblerait que vous ayez changé le lien envoyé, ce qui empêche la réinitialisation du mot de passe.")
        } else if (password !== passwordVerif) {
            setPasswordsAreDifferent(true);
            setErrorMessage("Les deux mots de passe indiqués ne sont pas égaux.")
        } else if (password.length <= 4) {
            setErrorMessage("Le mot de passe est trop court - veuillez utiliser au moins 5 caractères.")
        } else {
            setPasswordsAreDifferent(false);
            setErrorMessage("");
            props.sendResetPassword(token, password);
        }
    }

    return (
        <LoggedOutLayout isLoggedIn={props.isLoggedIn}>
            <Container component="main" maxWidth="xs">
                <div className="login-form">
                    <Box fontSize="h6.fontSize" m={1}>
                        Réinitialisation du mot de passe
                    </Box>
                    <TextField
                        id="password"
                        label="Nouveau mot de passe"
                        value={password}
                        onChange={handlePasswordChange}
                        margin="normal"
                        variant="outlined"
                        type="password"
                        required
                        fullWidth
                        autoFocus
                        error={passwordsAreDifferent}
                        disabled={props.passwordResetIsLoading}
                    />
                    <TextField
                        id="password-verif"
                        label="Confirmer le nouveau mot de passe"
                        value={passwordVerif}
                        onChange={handlePasswordVerifChange}
                        margin="normal"
                        variant="outlined"
                        type="password"
                        required
                        fullWidth
                        error={passwordsAreDifferent}
                        disabled={props.passwordResetIsLoading}
                    />
                    <LoadingButton
                        variant="contained"
                        color="primary"
                        disabled={!password || props.passwordResetSuccessful}
                        onClick={handleSubmit}
                        loading={props.passwordResetIsLoading}
                    >
                        Réinitialiser le mot de passe
                    </LoadingButton>
                    {props.passwordResetSuccessful
                        ? <Alert severity="success">Le mot de passe a été réinitialisé. Cliquez <Link href="/login">ici</Link> pour retourner à la page de connexion.</Alert>
                        : null
                    }
                    {errorMessage
                        ? <Alert severity="error">{errorMessage}</Alert>
                        : null
                    }
                    {props.passwordResetErrorMessage
                        ? <Alert severity="error">{props.passwordResetErrorMessage}</Alert>
                        : null
                    }
                </div>
            </Container>
        </LoggedOutLayout>
    );
}
