import React from 'react';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';


/* Props:
		- text => string.
*/
export function HelpButton(props) {
	return (
		<Tooltip title={props.text} aria-label="help">
			<IconButton aria-label="help-button">
				<HelpOutlineIcon fontSize="large" />
			</IconButton>
		</Tooltip>
	);
}