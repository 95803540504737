import * as types from "../actions";

export const initialState = {
    realtimeAttendancy: [],
    errorMessage: undefined,
    isFetching: false,
    isCreatingAttendanceRecord: false,
    attendanceRecordCreationErrorMessage: undefined,
    attendanceRecordCreated: false,
    attendanceRecords: [],
    attendanceRecordsRetrievalErrorMessage: undefined,
    isFetchingAttendanceRecords: false
};

export const RealTimeAttendancyReducer = (state = initialState, action) => {
    switch (action.type) {
        case types.FETCH_REALTIMEATTENDANCY_REQUEST:
            return Object.assign({}, state, {
                realtimeAttendancy: [],
                errorMessage: undefined,
                isFetching: true
            });
        case types.FETCH_REALTIMEATTENDANCY_SUCCESS:
            return Object.assign({}, state, {
                realtimeAttendancy: adaptRealTimeAttendancyFormat(action.realtimeAttendancy),
                errorMessage: undefined,
                isFetching: false
            });
        case types.FETCH_REALTIMEATTENDANCY_FAILURE:
            return Object.assign({}, state, {
                errorMessage: action.errorMessage,
                isFetching: false
            });
        case types.CREATE_ATTENDANCERECORD_REQUEST:
            return Object.assign({}, state, {
                attendanceRecordCreationErrorMessage: undefined,
                isCreatingAttendanceRecord: true,
                attendanceRecordCreated: false
            });
        case types.CREATE_ATTENDANCERECORD_SUCCESS:
            return Object.assign({}, state, {
                attendanceRecordCreationErrorMessage: undefined,
                isCreatingAttendanceRecord: false,
                attendanceRecordCreated: true
            });
        case types.CREATE_ATTENDANCERECORD_FAILURE:
            return Object.assign({}, state, {
                attendanceRecordCreationErrorMessage: action.errorMessage,
                isCreatingAttendanceRecord: false,
                attendanceRecordCreated: false
            });
        case types.FETCH_STUDENT_ATTENDANCE_RECORDS_REQUEST:
            return Object.assign({}, state, {
                attendanceRecords: [],
                attendanceRecordsRetrievalErrorMessage: undefined,
                isFetchingAttendanceRecords: true
            });
        case types.FETCH_STUDENT_ATTENDANCE_RECORDS_SUCCESS:
            return Object.assign({}, state, {
                attendanceRecords: action.attendanceRecords,
                attendanceRecordsRetrievalErrorMessage: undefined,
                isFetchingAttendanceRecords: false
            });
        case types.FETCH_STUDENT_ATTENDANCE_RECORDS_FAILURE:
            return Object.assign({}, state, {
                attendanceRecordsRetrievalErrorMessage: action.errorMessage,
                isFetchingAttendanceRecords: false
            });
        default:
            return state;
    }
}

// Input: [Events]. Events = startTime, endTime, title, presentStudents, absentStudents (Student = firstName, lastName, dateTime (Date)).
function adaptRealTimeAttendancyFormat(rawAttendancy) {
    return rawAttendancy.map((attendancy, index) => {
        attendancy.start = new Date(attendancy.startTime);
        attendancy.end = new Date(attendancy.endTime);
        attendancy.allStudentsPresent = attendancy.absentStudents.length === 0;
        attendancy.allStudentsAbsent = attendancy.presentStudents.length === 0;
        attendancy.allDay = false;
        const totalNumberOfStudents = attendancy.presentStudents.length + attendancy.absentStudents.length;
        attendancy.title = attendancy.presentStudents.length + " présents sur " + totalNumberOfStudents;
        attendancy.isOccurring = attendancy.end > new Date();
        attendancy.presentStudents.map(student => {
            student.firstLogForPeriod = new Date(student.dateTime);
            return student;
        });
        return attendancy;
    });
}